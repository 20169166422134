import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  messageCtwaContext: {
    display: "flex",
    textDecoration: "none",
    color: "inherit",
    marginBottom: 10,
    "&:hover": {
      textDecoration: "none",
    },
  },

  messageCtwaContextContent: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: 10,
    paddingTop: 5,
    backgroundColor: "rgba(255, 255, 255, 0.5)",
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
  },

  messageCtwaContextThumbnail: {
    width: "65px",
    height: "100%",
    objectFit: "cover",
  },

  messageCtwaContextTitle: {
    fontWeight: 600,
    fontSize: 11,
  },

  messageCtwaContextDescription: {
    fontWeight: 100,
    fontStyle: "italic",
    fontSize: 10,
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    // tablet
    [theme.breakpoints.down("md")]: {
      width: "250px",
    },
    // mobile
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

const MessageMetadata = ({ disableHyperlink, metadata }) => {
  const classes = useStyles();
  return (
    <a
      href={disableHyperlink ? "#" : metadata.sourceUrl}
      target={disableHyperlink ? "_self" : "_blank"}
      className={classes.messageCtwaContext}
    >
      <div>
        <img
          src={metadata.thumbnail || "https://ui-avatars.com/api/?background=random&color=fff&name=" + metadata.title}
          alt="thumbnail"
          className={classes.messageCtwaContextThumbnail}
        />
      </div>
      <div className={classes.messageCtwaContextContent}>
        <div className={classes.messageCtwaContextTitle}>{metadata.title}</div>
        <div className={classes.messageCtwaContextDescription}>
          {metadata.description}
        </div>
        <div className={classes.messageCtwaContextDescription}>
          {metadata.sourceUrl}
        </div>
      </div>
    </a>
  );
};

export default MessageMetadata;
