import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Button,
  TextField,
  CircularProgress,
  InputAdornment,
  Tooltip,
  IconButton,
  Grid,
  Link,
} from "@material-ui/core";
import QRCode from "qrcode.react";
import { useHistory } from "react-router-dom";
import api from "../../services/api";
import { getAppName } from "../../config";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
  },
  qrCode: {
    width: "100%",
    objectFit: "contain",
  },
  qrCodeContainer: {
    padding: theme.spacing(2),
    backgroundColor: "#f5f5f5",
  },
  input: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: "100%",
  },
}));

const TwoFactorAuth = ({ user }) => {
  const classes = useStyles();
  const [secret, setSecret] = useState("");
  const [loading, setLoading] = useState(false);
  const [backupCode, setBackupCode] = useState("");
  const [qrVisible, setQrVisible] = useState(false);
  const history = useHistory();

  useEffect(() => {
    // Generate a new TOTP secret when the component mounts
    const generateSecret = async () => {
      setLoading(true);
      try {
        const response = await api.get("/auth/generate-totp-secret");
        setSecret(response.data.secret);
        setBackupCode(response.data.backupCode);
        setQrVisible(true);
      } catch (error) {
        toast.error("Failed to generate 2FA QR Code, please refresh the page.");
      } finally {
        setLoading(false);
      }
    };

    generateSecret();
  }, []);

  const handleFinishSetup = async () => {
    setLoading(true);

    try {
      const response = await api.post("/auth/setup-totp", {
        secret,
        backupCode,
      });

      if (response.status === 200) {
        toast.success("2FA setup successfully");
        history.push("/settings");
      } else {
        toast.error("Failed to setup 2FA, please try again.");
      }
    } catch (error) {
      toast.error("Failed to setup 2FA, please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className={classes.paper}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: 8,
            }}
          >
            <Typography variant="h6" className={classes.title}>
              Mobile Authenticator Setup
            </Typography>
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              onClick={handleFinishSetup}
            >
              Enable 2FA
            </Button>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography variant="body1">
              1. Install one of the following applications on your mobile:
            </Typography>
            <ul>
              <li>
                Google Authenticator
                <p>
                  <Link href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&pcampaignid=web_share" target="_blank">
                    Android
                  </Link>
                  <br />
                  <Link href="https://apps.apple.com/us/app/google-authenticator/id388497605" target="_blank">
                    iOS
                  </Link>
                </p>
              </li>
              <li>
                Microsoft Authenticator
                <p>
                <Link href="https://apps.apple.com/us/app/microsoft-authenticator/id983156458" target="_blank">
                  iOS
                </Link>
                <br />
                <Link href="https://play.google.com/store/apps/details?id=com.azure.authenticator&pcampaignid=web_share" target="_blank">
                  Android
                </Link>
                </p>
              </li>
              <li>
                FreeOTP
                <p><Link href="https://play.google.com/store/apps/details?id=org.fedorahosted.freeotp&pcampaignid=web_share" target="_blank">
                  Android
                </Link>
                <br />
                <Link href="https://apps.apple.com/us/app/freeotp-authenticator/id872559395" target="_blank">
                  iOS
                </Link>
                </p>
              </li>
            </ul>
          </Grid>
          {loading ? (
            <CircularProgress />
          ) : (
            <>
              <Grid item xs={12} md={6}>
                <Typography variant="body1">
                  2. Open the application and scan the barcode:
                </Typography>
                <div>
                  {!qrVisible ? (
                    <Typography variant="body1">
                      No QR code available, please refresh the page.
                    </Typography>
                  ) : (
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <QRCode
                          value={`otpauth://totp/YourApp:${
                            user.name
                          }?secret=${secret}&issuer=${getAppName()}`}
                          className={classes.qrCode}
                          renderAs="svg"
                          size={256}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <div className={classes.qrCodeContainer}>
                          <Typography variant="body1">2FA Details:</Typography>
                          <Typography variant="body2">
                            Account: {user.name}
                          </Typography>
                          <Typography variant="body2">
                            Issuer: {getAppName()}
                          </Typography>
                          <TextField
                            className={classes.input}
                            label="Secret Code"
                            variant="outlined"
                            value={secret}
                            InputProps={{
                              readOnly: true,
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Tooltip title="Copy" aria-label="copy">
                                    <IconButton
                                      aria-label="copy"
                                      onClick={() => {
                                        navigator.clipboard.writeText(secret);
                                      }}
                                    >
                                      <FileCopyIcon />
                                    </IconButton>
                                  </Tooltip>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  )}
                </div>
              </Grid>
              {/* <Grid item xs={12}>
                {backupCode ? (
                  <TextField
                    className={classes.input}
                    label="Backup Code"
                    variant="outlined"
                    value={backupCode}
                    InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          <Tooltip title="Copy" aria-label="copy">
                            <IconButton
                              aria-label="copy"
                              onClick={() => {
                                navigator.clipboard.writeText(backupCode);
                              }}
                            >
                              <FileCopyIcon />
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }}
                  />
                ) : (
                  <Typography variant="body1">
                    No backup code available, please refresh the page.
                  </Typography>
                )}
              </Grid> */}
            </>
          )}
        </Grid>
      </div>
    </div>
  );
};

export default TwoFactorAuth;
