import React from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { useEffect, useState } from "react";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";

const ConnectionSelect = ({ selectedConnectionId, onChange, channel }) => {
  const [connections, setConnections] = useState([]);

  const handleChange = (event) => {
    onChange(event.target.value);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      try {
        const response = await api.get("/whatsapp", {
          params: { channel },
        });
        setConnections(response.data);
      } catch (err) {
        console.error("Error fetching connections:", err);
        setConnections([]);
      }
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [channel]);

  return (
    <FormControl fullWidth margin="dense" variant="outlined">
      <InputLabel>{i18n.t("connectionSelect.inputLabel")}</InputLabel>
      <Select
        value={selectedConnectionId}
        labelWidth={150}
        onChange={handleChange}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          getContentAnchorEl: null,
        }}
      >
        {connections && connections.length > 0 ? (
          connections.map((connection) => (
            <MenuItem key={connection.id} value={connection.id}>
              {connection.name}
            </MenuItem>
          ))
        ) : (
          <MenuItem value={0} disabled>
            {i18n.t("connectionSelect.noConnection")}
          </MenuItem>
        )}
      </Select>
    </FormControl>
  );
};

export default ConnectionSelect;
