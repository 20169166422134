import React, { useEffect, useContext, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  Typography,
  TextField,
  Button,
  Paper,
  CircularProgress,
} from "@material-ui/core";
import { useLocation, useHistory } from "react-router-dom";
import { AuthContext } from "../../context/Auth/AuthContext";
import { i18n } from "../../translate/i18n";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    boxShadow: theme.shadows[5],
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  input: {
    marginBottom: theme.spacing(2),
    width: "100%",
  },
  button: {
    marginTop: theme.spacing(2),
  },
  loading: {
    marginTop: theme.spacing(2),
  },
  buttonTotp: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
}));

const VerifyTOTP = () => {
  const classes = useStyles();
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const userId = location.state?.userId || null;

  const { verifyTOTP } = useContext(AuthContext);

  const handleVerify = async (e) => {
    e.preventDefault();

    setLoading(true);
    verifyTOTP({ code, userId });
    setLoading(false);
  };

  useEffect(() => {
    if (!userId) {
      history.push("/");
    }
  }, [history, userId]);

  return (
    <Container className={classes.container}>
      <Paper className={classes.paper}>
        <Typography variant="h5" className={classes.title}>
          {i18n.t("totp.verify.title")}
        </Typography>
        <form onSubmit={handleVerify}>
          <TextField
            className={classes.input}
            label={i18n.t("totp.verify.code")}
            variant="outlined"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            required
          />
          <div className={classes.buttonTotp}>
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              type="submit"
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={24} />
              ) : (
                i18n.t("totp.verify.button")
              )}
            </Button>
            <Button
              className={classes.button}
              variant="contained"
              color="secondary"
              onClick={() => history.push("/")}
            >
              {i18n.t("totp.verify.cancel")}
            </Button>
          </div>
        </form>
      </Paper>
    </Container>
  );
};

export default VerifyTOTP;
