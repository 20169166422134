import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Button,
  CircularProgress,
  TextField,
} from "@material-ui/core";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import { i18n } from "../../translate/i18n";
import ResourceSelect from "../ResourceSelect";
import { toast } from "react-toastify";
import api from "../../services/api";
import toastError from "../../errors/toastError";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginRight: theme.spacing(1),
    flex: 1,
  },

  extraAttr: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const GroupSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
});

const PermissionModal = ({ open, onClose, groupPermissionId }) => {
  const initialState = {
    name: "",
    groupPermissionId: "",
  };
  const classes = useStyles();
  const [group, setGroup] = useState(initialState);
  const [selectedResourceIds, setSelectedResourceIds] = useState([]);

  const handleClose = () => {
    setGroup(initialState);
    setSelectedResourceIds([]);
    onClose();
  };

  const handleSaveGroupPermission = async (values) => {
    const permissionData = { ...values, resourceIds: selectedResourceIds };
    try {
      if (groupPermissionId) {
        await api.put(`/permission/group/update/${groupPermissionId}`, permissionData);
      } else {
        await api.post("/permission/group/create", permissionData);
      }
      toast.success(i18n.t("permissionModal.success"));
      handleClose();
    } catch (err) {
      toastError(err);
    }
  };

  useEffect(() => {
    if (groupPermissionId) {
      const fetchGroup = async () => {
        const { data } = await api.get(`/permission/group/${groupPermissionId}`);
        setGroup(data);

        const resourceIds = data.resources?.map((resource) => resource.id);
        setSelectedResourceIds(resourceIds);
      };
      fetchGroup();
    }
  }, [groupPermissionId]);

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        scroll="paper"
        aria-labelledby="permission-dialog-title"
      >
        <DialogTitle id="permission-dialog-title">
          {groupPermissionId
            ? `${i18n.t("permissionModal.title.edit")}`
            : `${i18n.t("permissionModal.title.add")}`}
        </DialogTitle>
        <Formik
          initialValues={group}
          enableReinitialize={true}
          validationSchema={GroupSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSaveGroupPermission(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ values, errors, touched, isSubmitting }) => (
            <Form>
              <DialogContent dividers>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Field
                      as={TextField}
                      label={i18n.t("permissionModal.form.name")}
                      name="name"
                      fullWidth
                      autoFocus
                      error={touched.name && Boolean(errors.name)}
                      helperText={touched.name && errors.name}
                      variant="outlined"
                      margin="dense"
                      aria-label={i18n.t("permissionModal.form.name")}
                      inputProps={{
                        "aria-describedby": touched.name && errors.name ? "name-error" : undefined,
                      }}
                    />
                    {touched.name && errors.name && (
                      <span id="name-error" className="sr-only">
                        {errors.name}
                      </span>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <ResourceSelect
                      selectedResourceIds={selectedResourceIds}
                      onChange={(selectedIds) => setSelectedResourceIds(selectedIds)}
                      aria-label={i18n.t("permissionModal.form.resources")}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  color="secondary"
                  disabled={isSubmitting}
                  variant="outlined"
                >
                  {i18n.t("permissionModal.buttons.cancel")}
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                  variant="contained"
                  className={classes.btnWrapper}
                >
                  {groupPermissionId
                    ? `${i18n.t("permissionModal.buttons.okEdit")}`
                    : `${i18n.t("permissionModal.buttons.okAdd")}`}
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default PermissionModal;
