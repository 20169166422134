import React, { useState, useEffect } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginRight: theme.spacing(1),
    flex: 1,
  },

  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  colorAdorment: {
    width: 20,
    height: 20,
  },
}));

const BusinessHoursSchema = Yup.object().shape({
  dayOfWeek: Yup.string().required(),
  startTime: Yup.string()
    .nullable()
    .when(["is24Hours", "isClosed"], {
      is: (is24Hours, isClosed) => !is24Hours && !isClosed,
      then: () => Yup.string().nullable().required("Start time is required"),
      otherwise: () => Yup.string().nullable().optional(),
    }),

  endTime: Yup.string()
    .nullable()
    .when(["is24Hours", "isClosed"], {
      is: (is24Hours, isClosed) => !is24Hours && !isClosed,
      then: () => Yup.string().nullable().required("End time is required"),
      otherwise: () => Yup.string().nullable().optional(),
    }),
  is24Hours: Yup.boolean(),
  isClosed: Yup.boolean(),
  breaks: Yup.array().of(
    Yup.object().shape({
      startTime: Yup.string().nullable().required(),
      endTime: Yup.string().nullable().required(),
    })
  ),
});

const BusinessHoursModal = ({ open, onClose, businessHoursId }) => {
  const classes = useStyles();
  const initialValues = {
    dayOfWeek: "",
    startTime: "",
    endTime: "",
    is24Hours: false,
    isClosed: false,
    breaks: [
      {
        startTime: "",
        endTime: "",
      },
    ],
  };
  const [businessHours, setBusinessHours] = useState(initialValues);
  const [isLoading, setIsLoading] = useState(false);
  const daysMap = {
    1: "Monday",
    2: "Tuesday",
    3: "Wednesday",
    4: "Thursday",
    5: "Friday",
    6: "Saturday",
    7: "Sunday",
  };

  useEffect(() => {
    async function loadBusinessHours() {
      if (businessHoursId) {
        try {
          const response = await api.get(`/business-hours/${businessHoursId}`);
          setBusinessHours(response.data);
        } catch (error) {
          toastError(error);
        }
      }
    }
    loadBusinessHours();
  }, [businessHoursId]);

  const handleSaveBusinessHours = async (data) => {
    try {
      setIsLoading(true);
      if (businessHoursId) {
        await api.put(`/business-hours/${businessHoursId}`, data);
      } else {
        await api.post("/business-hours", data);
      }
      toast.success(i18n.t("businessHoursModal.toasts.success"));
      handleClose();
    } catch (error) {
      toastError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    onClose();
    setBusinessHours(initialValues);
  };

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        maxWidth="sm"
        fullWidth
        onClose={handleClose}
        scroll="paper"
      >
        <DialogTitle>
          {businessHoursId
            ? `${i18n.t("businessHoursModal.title.edit")} - ${
                daysMap[businessHours.dayOfWeek] || ""
              }`
            : `${i18n.t("businessHoursModal.title.add")}`}
        </DialogTitle>
        <Formik
          initialValues={businessHours}
          enableReinitialize={true}
          validationSchema={BusinessHoursSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSaveBusinessHours(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ touched, errors, isSubmitting, values, setFieldValue }) => (
            <Form>
              <DialogContent dividers>
                {!values.is24Hours && !values.isClosed && (
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Field
                        as={TextField}
                        name="startTime"
                        type="time"
                        label={i18n.t("businessHoursModal.labels.startTime")}
                        value={
                          values.startTime !== null ? values.startTime : ""
                        }
                        variant="outlined"
                        fullWidth
                        className={classes.textField}
                        error={touched.startTime && Boolean(errors.startTime)}
                        helperText={touched.startTime && errors.startTime}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        as={TextField}
                        name="endTime"
                        type="time"
                        label={i18n.t("businessHoursModal.labels.endTime")}
                        value={values.endTime !== null ? values.endTime : ""}
                        variant="outlined"
                        fullWidth
                        className={classes.textField}
                        error={touched.endTime && Boolean(errors.endTime)}
                        helperText={touched.endTime && errors.endTime}
                      />
                    </Grid>
                  </Grid>
                )}
                <FormControlLabel
                  control={
                    <Field
                      as={Checkbox}
                      name="is24Hours"
                      color="primary"
                      checked={values.is24Hours}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setFieldValue("isClosed", false);
                        }
                        setFieldValue("is24Hours", e.target.checked);
                      }}
                    />
                  }
                  label={i18n.t("businessHoursModal.labels.is24Hours")}
                />
                <FormControlLabel
                  control={
                    <Field
                      as={Checkbox}
                      name="isClosed"
                      color="primary"
                      checked={values.isClosed}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setFieldValue("is24Hours", false);
                        }
                        setFieldValue("isClosed", e.target.checked);
                      }}
                    />
                  }
                  label={i18n.t("businessHoursModal.labels.isClosed")}
                />

                {!values.isClosed && (
                  <>
                    <Divider style={{ margin: "16px 0" }} />

                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="body2">
                          {i18n.t("businessHoursModal.labels.breaks")}
                        </Typography>
                      </Grid>
                      {values.breaks.map((breakHour, index) => (
                        <React.Fragment key={index}>
                          <Grid item xs={6}>
                            <Field
                              as={TextField}
                              name={`breaks.${index}.startTime`}
                              type="time"
                              label={i18n.t(
                                "businessHoursModal.labels.startTime"
                              )}
                              value={
                                breakHour.startTime !== null
                                  ? breakHour.startTime
                                  : ""
                              }
                              variant="outlined"
                              fullWidth
                              className={classes.textField}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Field
                              as={TextField}
                              name={`breaks.${index}.endTime`}
                              type="time"
                              label={i18n.t(
                                "businessHoursModal.labels.endTime"
                              )}
                              value={
                                breakHour.endTime !== null
                                  ? breakHour.endTime
                                  : ""
                              }
                              variant="outlined"
                              fullWidth
                              className={classes.textField}
                            />
                          </Grid>
                        </React.Fragment>
                      ))}
                    </Grid>
                    <Box display="flex" justifyContent="flex-end" mt={2}>
                      <Button
                        onClick={() => {
                          setFieldValue("breaks", [
                            ...values.breaks,
                            { startTime: null, endTime: null },
                          ]);
                        }}
                        color="primary"
                        variant="outlined"
                      >
                        {i18n.t("businessHoursModal.buttons.addBreak")}
                      </Button>
                    </Box>
                  </>
                )}
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  color="secondary"
                  disabled={isSubmitting}
                  variant="outlined"
                >
                  {i18n.t("businessHoursModal.buttons.cancel")}
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  className={classes.btnWrapper}
                >
                  {businessHoursId
                    ? `${i18n.t("businessHoursModal.buttons.okEdit")}`
                    : `${i18n.t("businessHoursModal.buttons.okAdd")}`}
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default BusinessHoursModal;
