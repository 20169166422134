import {
  Button,
  CircularProgress,
  Divider,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import VoicemailIcon from "@material-ui/icons/Voicemail";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { toast } from "react-toastify";
import { i18n } from "../../translate/i18n";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import { useSettingsContext } from "../../context/Setting/SettingsContext";

const LS_NAME = "audioMessageRate";

const useStyles = makeStyles((theme) => ({
  audio: {
    marginTop: 10,
    marginBottom: 10,
    display: "flex",
    gap: 10,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  buttons: {
    display: "flex",
    gap: 10,
  },
  transcriptions: {
    marginTop: 10,
    lineHeight: "16px",
    fontStyle: "italic",
    fontSize: "12px",
  },
}));

const Audio = ({ url, messageId, transcriptions }) => {
  const classes = useStyles();
  const audioRef = useRef(null);
  const [audioRate, setAudioRate] = useState(
    parseFloat(localStorage.getItem(LS_NAME) || "1")
  );
  const [showButtonRate, setShowButtonRate] = useState(false);
  const [submitVoice, setSubmitVoice] = useState(false);
  const [showButtonVoiceToText, setShowButtonVoiceToText] = useState(false);

  const { settings } = useSettingsContext();

  useEffect(() => {
    audioRef.current.playbackRate = audioRate;
    localStorage.setItem(LS_NAME, audioRate);
  }, [audioRate]);

  useEffect(() => {
    audioRef.current.onplaying = () => {
      setShowButtonRate(true);
    };
    audioRef.current.onpause = () => {
      setShowButtonRate(false);
    };
    audioRef.current.onended = () => {
      setShowButtonRate(false);
    };
  }, []);

  useEffect(() => {
    const voiceToTextStatus =
      settings &&
      settings.length > 0 &&
      settings.find((item) => item.key === "voiceToTextStatus")?.value;

    setShowButtonVoiceToText(voiceToTextStatus === "enabled");
  }, [settings]);

  const toogleRate = () => {
    let newRate = null;

    switch (audioRate) {
      case 0.5:
        newRate = 1;
        break;
      case 1:
        newRate = 1.5;
        break;
      case 1.5:
        newRate = 2;
        break;
      case 2:
        newRate = 0.5;
        break;
      default:
        newRate = 1;
        break;
    }

    setAudioRate(newRate);
  };

  const transcribeMessage = async () => {
    setSubmitVoice(true);

    try {
      await api.post("/messages/" + messageId + "/audio-to-text", {
        audioUrl: url,
      });

      toast.success(i18n.t("audioToText.success"));
    } catch (err) {
      toastError(err);
    }

    setSubmitVoice(false);
  };

  return (
    <>
      <div className={classes.audio}>
        <audio ref={audioRef} controls>
          <source src={url} type="audio/ogg"></source>
          <source src={url} type="audio/mp3"></source>
          <source src={url} type="audio/mpeg"></source>
        </audio>
        <div className={classes.buttons}>
          {showButtonRate && <Button onClick={toogleRate}>{audioRate}x</Button>}
          {showButtonVoiceToText && (
            <Tooltip title={i18n.t("audioToText.tooltip")}>
              <Button onClick={transcribeMessage} disabled={submitVoice}>
                <VoicemailIcon />
                {submitVoice && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </Button>
            </Tooltip>
          )}
        </div>
      </div>
      {transcriptions &&
        transcriptions.length > 0 &&
        transcriptions.map((item) => (
          <>
            <Divider />
            <Typography key={item.id} className={classes.transcriptions}>
              {item.text}
            </Typography>
          </>
        ))}
    </>
  );
};

export default Audio;
